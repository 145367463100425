<template>
  <b-modal 
    id="modalNewCompanies" 
    :title="$t('Nueva empresa')" 
    no-close-on-backdrop 
    centered 
    modal-class="dialog-800"
    @close="close"
    hide-footer
  >
    <form-render :form.sync="formNewCompanies"
      :fields="fields"
      containerButtonsClass="col-sm-12"
      :key="keyFormRenderNewCompanies"
      ref="formRenderNewCompanies"
      @send="createCompany"
    >
      <template #adress_info>
        <h5>{{ $t("Dirección casa matriz") }}</h5>
      </template>
      <template #company_info>
        <h5>{{ $t("Datos de la empresa") }}</h5>
      </template>
      <template #billing_data>
        <h5>{{ $t("Datos de facturación") }}</h5>
      </template>
      <template #authority_contacts>
        <h5>{{ $t("Contacto tomador decisiones") }}</h5>
      </template>
      <template #msg_direct_billing>
        <span>* Si selecciona esta opción, el seller tendra la facturación independiente al Marketplace</span>
      </template>
      <template #emails_errors>
        <small class="text-danger">Correos ingresados no válidos</small>
      </template>
    </form-render>
    <div class="footer-modal-coverage">
      <b-button class="float-right mt-1 mb-1" variant="warning" @click="ok" :disabled="loading.createCompany">
        <span v-if="!loading.createCompany" class="mb-1">Agregar</span>
        <span class="mt-1 p-2" v-if="loading.createCompany"><b-spinner small  /> </span>
      </b-button>
    </div>
  </b-modal>
</template>
 
<script>
import { mapGetters } from 'vuex'
import BaseServices from '@/store/services/index'
import Swal from 'sweetalert2'

export default {
  name: 'modalNewCompanies',
  props: ['cleanCompaniesFilter', 'onClose'],
  data() {
    return {
      fields: [],
      formNewCompanies: {},
      keyFormRenderNewCompanies: 0,
      is_MKP: false,
      searchFn: { fx:  e => this.searchInPlaceId(e), nChars: 3, debounce: 600, persistSearch: true},
      VARIABLES: Object.freeze({
        CHILE_ID: 1,
        MEXICO_ID: 2,
        PERU_ID: 4,
        COMPANY_TYPES: [
          {text: 'Seller', id: 1, code: 'SELLER'},
          {text: 'Marketplace', id: 2, code: 'MKP'},
          {text: 'Empresa', id: 3, code: 'CMPN'}
        ]
      }),
      COUNTRY_LEVEL: Object.freeze({ //Esto lo tome literalmente de un switch case en platform
        1: null,
        2: 5,
        3: 16,
        4: 10,
        5: 20,
        6: 23
      }),
      billingData_MKP_CMPN: [
        { fieldType: 'FieldInput', name: 'contact_name', label: this.$t('Nombre de contacto'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info' },
        { fieldType: 'FieldInput', name: 'contact_phone', label: this.$t('Teléfono de contacto'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info' },
        { fieldType: 'FieldInput', name: 'credit_days', label: this.$t('Días de crédito'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'contact_emails', label: this.$t('Emails de contacto'), useLabel: false, containerClass: 'col-sm-12 col-md-12 container-info', validation: 'required', change: this.replaceEspaceForComma, textHelp: 'Ingrese las direcciones de correos separandolas con espacio, de forma automatica se pondra una coma' },
        { fieldType: 'FieldCheckbox', name: 'oc', containerClass: 'col-sm-12 col-md-4 container-info', options: [{id: true, text: 'OC'}]}
      ],
      billingData_Seller: [
        { fieldType: 'FieldCheckbox', name: 'direct_billing', containerClass: 'col-sm-12 col-md-4 container-info', options: [{id: true, text: 'Facturación Directa:'}], change: this.changeDirectBilling},
        { name: 'msg_direct_billing', useSlot: true, containerClass: 'col-sm-12 container-info', disabled: true }
      ],
      fieldsBillingData: [],
      arrayOfEmails: [],
      baseService: new BaseServices(this),
      loading: {
        createCompany: false
      },
      payloadBilling: {},
      authority_contact_roles: [
        {id: 1, text: 'Representante legal'},
        {id: 2, text: 'Gerencia'},
        {id: 3, text: 'Jefatura'},
        {id: 4, text: 'Encargado de área'},
        {id: 5, text: 'Ejecutivo comercial'}
      ],
      fieldsContactDecisionMaker: [
        { name: 'authority_contacts', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1', disabled: true },
        { fieldType: 'FieldInput', name: 'full_name', label: this.$t('Nombre y Apellido'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info', validation: 'required' },
        { fieldType: 'FieldSelect', name: 'role_id', label: this.$t('Cargo'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info'},
        { fieldType: 'FieldInput', name: 'phone', label: this.$t('Teléfono de contacto'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info'},
        { fieldType: 'FieldInput', name: 'email', label: this.$t('Email de contacto'), useLabel: false, containerClass: 'col-sm-12 col-md-12 container-info', validation: 'required' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries',
      mySession: 'getSession'
    })
  },
  watch: {
    countries() {
      if (this.countries && !!this.countries.length) this.setInitialData()
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    }
  },
  mounted() {
    if (this.mySession.id) this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fieldsBillingData = this.billingData_MKP_CMPN
      this.fields = [
        { name: 'adress_info', useSlot: true, containerClass: 'col-sm-12 container-info', disabled: true },
        { fieldType: 'FieldSelect', useLabel: true, name: 'country', disabled: false, label: this.$t('País'), validation: 'required', options: this.countries, change: this.handleChangeCountry, addFlags: true, containerClass: 'col-12 col-md-4 fieldSelect' },
        { fieldType: 'FieldSelect', useLabel: true, name: 'place_id', label: this.$t('Comuna'), dependency: 'country', searchOnType: this.searchFn, containerClass: 'col-12 col-md-4 fieldSelect', validation: 'required' },
        { fieldType: 'FieldInput', name: 'address', label: this.$t('Dirección'), useLabel: false, containerClass: 'col-sm-12 container-info', validation: 'required' },
        { name: 'company_info', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1', disabled: true },
        { fieldType: 'FieldInput', name: 'name1', label: this.$t('Nombre de la empresa'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'name2', label: this.$t('Razón Social'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'dni', label: 'DNI', useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'business_activity', label: this.$t('Giro'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info', validation: 'required' },
        { fieldType: 'FieldInput', name: 'webpage', label: this.$t('Sitio web'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info' },
        { fieldType: 'FieldInput', name: 'code', label: this.$t('Código'), useLabel: false, containerClass: 'col-sm-12 col-md-4 container-info', validation: 'required' },
        { name: 'billing_data', useSlot: true, containerClass: 'col-sm-12 container-info pt-1 border-top mt-1', disabled: true },
        ...this.fieldsBillingData,
        ...this.fieldsContactDecisionMaker
        
      ]
      this.fields[this.getElementIndex(this.fields, 'role_id')].options = this.authority_contact_roles
      //Vistas solo para adm
      if (['superadmin', 'admin'].includes(this.mySession.role)) {
        this.fields.splice(
          this.getElementIndex(this.fields, 'code') + 1,
          0,          
          { fieldType: 'FieldSelect', useLabel: true, name: 'executive_id', disabled: false, label: this.$t('Ejecutivo comercial'), validation: 'required', containerClass: 'col-12 col-md-4 fieldSelect' },
          { fieldType: 'FieldSelect', useLabel: true, name: 'company_type', disabled: false, label: this.$t('Tipo de empresa'), validation: 'required', options: this.VARIABLES.COMPANY_TYPES, change: this.handleCompanyType, containerClass: 'col-12 col-md-4 fieldSelect' }
        )
      }

      if (['marketplace'].includes(this.mySession.role)) {
        this.is_MKP = true
        this.handleCompanyType('', {text: 'Seller', id: 1, code: 'SELLER'})
      }
      
    },
    /* 
    * Capturar el cambio del pais y cambiar las opciones
    */
    handleChangeCountry(name, value) {
      this.cleanForm('country')
      if (!value) {
        this.keyFormRenderNewCompanies++
        return
      }
      //Se llama a los ejecutivos
      this.getExecutivesByCountry(value.id)
      //Capturar los index para poder mostrar u ocultar a voluntad
      const indexCountry = this.getElementIndex(this.fields, 'country') + 1
      // Se muestra para CO, PE, AR, EC
      if (value.id !== this.VARIABLES.CHILE_ID && value.id !== this.VARIABLES.MEXICO_ID) {
        //En caso de que no este el input se crea
        if (this.getElementIndex(this.fields, 'place_level_1') === -1) {
          this.fields.splice(
            indexCountry,
            this.getElementIndex(this.fields, 'zip_code') !== -1 ? 1 : 0,
            {fieldType: 'FieldSelect', useLabel: true, name: 'place_level_1', validation: 'required', dependency: 'country',  searchOnType: { fx: e => this.searchInPlaceId(e, null, true), nChars: 3, debounce: 600, persistSearch: true }, containerClass: 'col-12 col-md-4 fieldSelect' }
          )
        }
        //Se hacen las traducciones por pais
        this.changeTextI18n(value.code, 'place_level_1', value.id === this.VARIABLES.PERU_ID ? 'Place_level2' : 'Place_level1')
      }
      else if (value.id === this.VARIABLES.MEXICO_ID) {
        //Eliminamos esta propiedad ya q para mexico se llena con el zipcode
        delete this.fields[this.getElementIndex(this.fields, 'place_id')].searchOnType
        // la condicion en 2do. param del splice es por si existe el place_level_1 sea reemplazado por el zip_code, de lo contrario se agrega un input nuevo
        this.fields.splice(
          indexCountry,
          this.getElementIndex(this.fields, 'place_level_1') === -1 ? 0 : 1,
          {fieldType: 'FieldSelect', useLabel: true, name: 'zip_code', label: this.$t('Código Postal'), dependency: 'country', change: this.handleChangeZipCode, validation: 'required', searchOnType: { fx: e => this.searchInPlaceId(e, true), nChars: 3, debounce: 600, persistSearch: true }, containerClass: 'col-12 col-md-4 fieldSelect' }
        )
      }
      else if (value.id === this.VARIABLES.CHILE_ID) {
        //Eliminar los inputs en caso de que existan
        if (this.getElementIndex(this.fields, 'place_level_1') !== -1) this.fields.splice(this.getElementIndex(this.fields, 'place_level_1'), 1)
        if (this.getElementIndex(this.fields, 'zip_code') !== -1) this.fields.splice(this.getElementIndex(this.fields, 'zip_code'), 1)
      }
      //Si se ha eliminado el searchOntype y porq se selecciono mexico y luego se cambio, lo agregamos
      if (value.id !== this.VARIABLES.MEXICO_ID && !this.fields[this.getElementIndex(this.fields, 'place_id')].searchOnType) this.fields[this.getElementIndex(this.fields, 'place_id')].searchOnType = this.searchFn
      //Esto siempre se traduce porque esta para todos los paises
      this.changeTextI18n(value.code, 'place_id', 'Place_id')
      this.changeTextI18n(value.code, 'dni', 'WORD_DNI')
      this.keyFormRenderNewCompanies++
    },
    /**
     * Cambiar la descripcion del texto por pais
     * @param {string} countryCode Codigo del pais
     * @param {number} elementName nombre del elemento para hacer la busqueda
     * @param {number} key El valor de la key en el json de las transcripciones
     */
    changeTextI18n(countryCode, elementName, key) {
      const texts = this.$i18nImport(`${countryCode.toLowerCase()}.es`)[`${countryCode.toLowerCase()}.es`]
      const index = this.fields.findIndex(el => el.name === elementName)
      this.fields[index].label = this.$t(texts[key])
    },
    /**
     * Para hacer la busqueda de los places desde los diferentes inputs
     * @param {string} value texto ingresado
     * @param {boolean} hasZipCode Esto es para cuando se llama desde el input del zipcode
     * @param {boolean} isFromPlaceLevel Esto es para cuando se llama desde el input del place_level_1
     */
    searchInPlaceId(value, hasZipCode = false, isFromPlaceLevel = false) {
      const countryID = this.formNewCompanies.country.id
      const params = {
        country_id: countryID
      }
      const queryParams = {
        str: value
      }
      //Agregar otros parametros en caso de que no se llame desde place_level_1 para AR, CO, EC, PE
      if (countryID !== this.VARIABLES.CHILE_ID && countryID !== this.VARIABLES.MEXICO_ID) {
        if (isFromPlaceLevel) {
          queryParams.level = this.COUNTRY_LEVEL[countryID]
        } else {
          queryParams.parent = this.formNewCompanies.place_level_1.id
        }
      }      
      const serviceName = !hasZipCode ? 'getPlacesCompanies' : 'getZipcodeCompanies'
      return this.baseService.callService(serviceName, queryParams, params)
        .then(resp => {
          const response = hasZipCode ? resp.data.zip_codes.map(val => ({'name': val, 'id': val})) : resp.data //El array se convierte en objeto
          response.map((row) => {
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          return response
        })
        .catch(err => {
          return err
        })
    },
    /**
    * Para llenar las opciones del input place_id para mexico, ya que se llena automatico dependiendo del zipcode seleccionado
    */
    handleChangeZipCode(name, value) {
      const params = {
        country_id: this.formNewCompanies.country.id
      }
      const queryParams = {
        'str': '',
        'zip-code': value.name
      }
      this.baseService.callService('getPlacesCompanies', queryParams, params)
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            //se convierte en string porue da error con los otros types del payload al agregarse como option
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.name
          })
          this.fields[this.getElementIndex(this.fields, 'place_id')].options = response
          this.keyFormRenderNewCompanies++
        })
    },
    changeDirectBilling(name, value) {
      if (value) {
        const indexDirectBilling = this.getElementIndex(this.fields, 'direct_billing')
        this.fields.splice(indexDirectBilling + 2, 0, ...this.billingData_MKP_CMPN)
      } else {
        this.formNewCompanies.direct_billing = false
        const indexContactName = this.getElementIndex(this.fields, 'contact_name')
        if (indexContactName !== -1) this.fields.splice(indexContactName, 5)
      }
      ['contact_name', 'contact_phone', 'credit_days', 'contact_emails', 'oc'].forEach(key =>  {
        delete this.formNewCompanies[key]
      })
    },
    /**
     * El caso de que el tipo de compañia sea seller se agrega un nuevo input que es MKP
     */
    handleCompanyType(name, value) {
      const indexMKP = this.getElementIndex(this.fields, 'marketplace')
      const indexExecutiveId = this.getElementIndex(this.fields, 'executive_id')
      if (value?.text === this.VARIABLES.COMPANY_TYPES[0].text && indexMKP === -1) {
        this.fields.splice(indexExecutiveId, 1)
        const indexNearEle = this.getElementIndex(this.fields, this.is_MKP ? 'code' : 'company_type')
        if (!this.is_MKP) this.fields.splice(indexNearEle + 1, 0, { fieldType: 'FieldSelect', useLabel: true, name: 'marketplace', validation: 'required', disabled: false, label: 'Marketplaces', containerClass: 'col-12 col-md-4 fieldSelect' })
        this.fieldsBillingData = this.billingData_Seller
        this.fields.splice(indexNearEle + (this.is_MKP ? 2 : 3), this.fields.length - 1, ...this.fieldsBillingData, ...this.fieldsContactDecisionMaker)
        if (!this.is_MKP) this.getMarketplaces()
      } else if (indexMKP > -1) {
        this.changeDirectBilling('direct_billing', false)
        const indexCompanyType = this.fields.findIndex(el => el.name === 'company_type')
        this.fields.splice(indexMKP, 1)
        this.fields.splice(indexCompanyType, 0, { fieldType: 'FieldSelect', useLabel: true, name: 'executive_id', disabled: false, label: 'Ejecutivo comercial', validation: 'required', containerClass: 'col-12 col-md-4 fieldSelect' })
        if (this.formNewCompanies?.country?.id) this.getExecutivesByCountry(this.formNewCompanies.country.id)
        this.fieldsBillingData = this.billingData_MKP_CMPN
        this.fields.splice(indexCompanyType + 3, 2, ...this.fieldsBillingData)
      }
    },
    getExecutivesByCountry(country_id) {
      const params = {
        country: country_id
      }
      this.baseService.callService('getExecutivesCompanies', params)
        .then(resp => {
          const response = {
            rows: resp.data.map(el => ({...el, text: el.name, code: el.id}))
          }
          this.fields[this.getElementIndex(this.fields, 'executive_id')].options = response.rows
          this.keyFormRenderNewCompanies++
        })
        .catch(err => {
          return err
        })
    },
    getMarketplaces () {
      this.baseService.callService('getMarketplacesCompanies')
        .then(resp => {
          const response = resp.data
          response.map((row) => {
            Object.keys(row).forEach((key) => {
              return row[key] = `${row[key]}`
            })
            row.text = row.code
          })
          this.fields[this.getElementIndex(this.fields, 'marketplace')].options = response
          this.keyFormRenderNewCompanies++
        })
        .catch(err => {
          return err
        })
    },
    //POST -> Creacion de la empresa
    createCompany() {
      this.arrayOfEmails = this.formNewCompanies.contact_emails ? this.formNewCompanies.contact_emails.split(',') : []
      if (this.arrayOfEmails.length > 0 && this.validateEmail(this.arrayOfEmails)) return 
      this.loading.createCompany = true
      const _countryID = this.formNewCompanies.country?.id
      const queryParams = {
        name1: this.formNewCompanies.name1,
        name2: this.formNewCompanies.name2,
        code: this.formNewCompanies.code,
        country_id: _countryID,
        dni: this.formNewCompanies.dni,
        business_activity: this.formNewCompanies.business_activity,
        webpage: this.formNewCompanies.webpage || null,
        marketplace: this.formNewCompanies.marketplace?.id,
        address: {
          place_id: this.formNewCompanies.place_id?.id,
          full_address: this.formNewCompanies.address
        },
        billing_info: {
          contact_name: this.formNewCompanies.contact_name,
          contact_email: this.arrayOfEmails,
          contact_phone: this.formNewCompanies.contact_phone
        },
        type: this.is_MKP ? 'Seller' : this.formNewCompanies.company_type?.text,
        credit_days: this.formNewCompanies.credit_days,
        oc: this.formNewCompanies.oc || null,
        executive_id: this.formNewCompanies.executive_id?.id || null,
        zip_code: this.formNewCompanies.zip_code || null,
        authority_contacts: [
          {
            full_name: this.formNewCompanies.full_name || null,
            role_id: this.formNewCompanies.role_id?.id || null,
            phone: this.formNewCompanies.phone || null,
            email: this.formNewCompanies.email || null
          }
        ]
      }
      if (this.formNewCompanies.company_type?.text === 'Seller' && this.formNewCompanies.direct_billing) queryParams.required_billing = true
      if (_countryID !== this.VARIABLES.CHILE_ID && _countryID !== this.VARIABLES.MEXICO_ID && this.formNewCompanies.place_level_1) queryParams.place_level_1 = this.formNewCompanies.place_level_1
      
      this.baseService.callService('createNewCompany', queryParams, {}, { fullResponseError: true })
        .then(response => {          
          if ((this.formNewCompanies.company_type?.text === 'Seller' && !this.formNewCompanies.direct_billing) || (this.is_MKP && !this.formNewCompanies.direct_billing)) {
            this.$success(this.$t('msg-exito-creacion-company'))
            return
          }
          this.$bvToast.toast(this.$t('msg-exito-creacion-company'), {
            title: 'Felicitaciones',
            variant: 'success',
            solid: true
          })
          this.createBillingData(response.data.company)
        })
        .catch(err => {
          const errorMsg =  !!err.data?.length ? err.data.join('<br>') : err.message
          this.$alert(this.$t('msg-problema-creacion-company', {code: errorMsg}))
          this.loading.createCompany = false
        })
        .finally(() => {
          if ((this.formNewCompanies.company_type?.text === 'Seller' && !this.formNewCompanies.direct_billing) || (this.is_MKP && !this.formNewCompanies.direct_billing)) {
            this.loading.createCompany = false
            this.$emit('cleanCompaniesFilter', false)
            this.close()
          }
        })
    },
    //POST -> Datos de facturacion
    createBillingData(company) {
      Swal.fire({
        title: 'Creando los datos de facturación',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })    
      const {role, organization} = this.mySession
      const idMkp = role === 'admin' && this.formNewCompanies.company_type?.text === 'Seller' ? this.formNewCompanies.marketplace?.id : organization?.id
      const company_type = this.formNewCompanies.company_type?.text

      const contactFormatter = this.arrayOfEmails.map((email, index) => {
        return {
          email,
          jobTitle: null,
          name: null,
          phoneNumber: null
        }
      })
      const manageDataCompany = {
        taxIdentificationNumber: this.formNewCompanies.dni,
        address: this.formNewCompanies.address,
        place_name: company.places_parents_names,
        place_code: Number(company.place_code),
        business_name: this.formNewCompanies.name2,
        business_activity: this.formNewCompanies.business_activity,
        contacts: contactFormatter,
        credit_days: parseInt(this.formNewCompanies.credit_days),
        required_oc: this.formNewCompanies.oc || false,
        overdue_payment_limit: null,
        company: {
          parentId: company_type === 'Marketplace' ? null : parseInt(company_type === 'Seller' ? idMkp : company.id),
          code: company.id.toString(),
          name: company.name1,
          type: company_type === 'Marketplace' ? {id: 1, platformName: 'Marketplace' } : company_type === 'Seller' ?  {id: 3, platformName: 'Seller' } : {id: 4, platformName: 'Empresa' },
          country: {
            id: this.formNewCompanies.country?.id,
            code: this.formNewCompanies.country?.code
          }
        }
      }
      const data = {
        name: 'saveManageBillingDataCompany',
        params: { company_id: parseInt(company.id)},
        queryParams: { ...manageDataCompany },
        showSuccess: true,
        onSuccess: () => this.successSaveBillingData(),
        onError: err => this.errorSavingBillingData(err)
      }
      this.$store.dispatch('fetchServiceStatusOnError', data)
    },
    successSaveBillingData() {
      this.$success(this.$t('msg-exito-company-facturacion'))
      this.endNewCompanySteps()
    },
    errorSavingBillingData(err) {
      let errorMsg = ''
      if (err.errors) {
        err.errors.forEach((e) => {
          errorMsg += Array.isArray(e.detail) ? e.detail.join('.<br>') : `${e.detail}.<br>`
        })
      } else {
        errorMsg = err.message
      }
      this.$alert(this.$t('msg-problema-billing-data', {code: errorMsg}))
      this.endNewCompanySteps()
    },
    endNewCompanySteps () {
      Swal.hideLoading()
      this.loading.createCompany = false
      this.$emit('cleanCompaniesFilter', false)
      this.close()
    },
    /**
     * Toma el onchange del email input para que en caso de espacio se agregue la coma (replicando el comportamiento de este form en platform)
     */
    replaceEspaceForComma (name, value) {
      const string = value
      this.formNewCompanies.contact_emails = string.replace(/ /g, ',')               
    },
    /**
     * Valida el formato de los emails ingresados
     * @param {array} emails grupo de correos ingresados
     */
    validateEmail (emails) {
      const filter = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
      const validate = emails.map(item => filter.test(item)).includes(false)
      this.setInvalidEmails(validate)
      return validate
    },
    /**
     * Replica el comportamiento del formulario en caso de que haya un email erroneo muestre el error
     * @param {boolean} isInvalid
     */
    setInvalidEmails(isInvalid) {
      const indexEmailsForm = this.getElementIndex(this.fields, 'emails_errors')
      if (isInvalid) {
        document.getElementsByName('contact_emails')[0].classList.add('invalid-mail-company')
        if (indexEmailsForm === -1) {
          this.fields.splice(
            this.getElementIndex(this.fields, 'contact_emails') + 1,
            0,
            {name: 'emails_errors', useSlot: true, containerClass: 'col-sm-12 container-info', disabled: true }
          )
        }        
      } else {
        document.getElementsByName('contact_emails')[0].classList.remove('invalid-mail-company')
        if (indexEmailsForm !== -1) this.fields.splice(indexEmailsForm, 1)
      }
    },
    getElementIndex(array, value) {
      return array.findIndex(fieldsData => fieldsData.name === value)
    },
    cleanForm(omit = null) {
      Object.keys(this.formNewCompanies).forEach(key =>  {
        if (key !== omit) delete this.formNewCompanies[key]
      })
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderNewCompanies.checkForm()
    },
    close() {
      this.$emit('onClose', 'modalNewCompanies')
      this.cleanForm()
      this.setInitialData() //Se refresque el formulario con los inpurts por defecto
    }
  }

}
</script>

<style lang="scss">
  .invalid-mail-company{
    border-color: red;
  }
</style>
