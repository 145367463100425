<template>
  <b-modal id="modalFilterAvancedCompanies" :title="$t('Filtro avanzado')" size="sm" :ok-title="$t('Filtrar')" ok-variant="warning" ok-only no-close-on-backdrop centered @close="close" @ok="ok">
    <form-render :form.sync="formFilterAdvancedCompanies" :fields="fields" containerButtonsClass="col-sm-12"
      ref="formRenderFilterAdvancedCompanies"/>
  </b-modal>
</template>
  
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'modalFilterAvancedCompanies',
  props: ['onClose', 'filter'],
  data() {
    return {
      form: {},
      fields: [],
      formFilterAdvancedCompanies: {},
      formRenderFilterAdvancedCompanies: 0
    }
  },
  computed: {
    ...mapGetters({
      countries: 'getCountries'
    })
  },
  watch: {
    countries() {
      if (this.countries && !!this.countries.length) this.setInitialData()
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fields = [
        { fieldType: 'FieldSelect', useLabel: true, name: 'country', disabled: false, label: 'País', containerClass: 'col-12 fieldSelect', options: this.countries, addFlags: true },
        { fieldType: 'FieldDatepicker', name: 'date_range', label: this.$t('Fecha de creación'), range: true, containerClass: 'col-sm-12' }
      ]
    },
    ok() {
      this.$emit('filter', this.formFilterAdvancedCompanies)
    },
    close() {
      this.$emit('closeModal', 'modalFilterAvancedCompanies')
    },
    cleanForm() {
      this.formFilterAdvancedCompanies = {}
    }
  }

}
</script>

<style></style>